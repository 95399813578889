.page-under_construction {
    .page-content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .secondary_text {
        line-height: 160%;
    }
}