

@font-face {
  font-family: "Graphik";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/Graphik-Thin.otf");
}

@font-face {
  font-family: "Graphik";
  font-weight: 200;
  font-style: normal;
  src: url("../fonts/Graphik-Extralight.otf");
}

@font-face {
  font-family: "Graphik";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/Graphik-Light.otf");
}

@font-face {
  font-family: "Graphik";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/Graphik-Regular.otf");
}

@font-face {
  font-family: "Graphik";
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/Graphik-RegularItalic.otf");
}

@font-face {
  font-family: "Graphik";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/Graphik-Medium.otf");
}

@font-face {
  font-family: "Graphik";
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/Graphik-Semibold.otf");
}

@font-face {
  font-family: "Graphik";
  font-weight: 600;
  font-style: italic;
  src: url("../fonts/Graphik-SemiboldItalic.otf");
}

@font-face {
  font-family: "Graphik";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/Graphik-Bold.otf");
}

@font-face {
  font-family: "Graphik";
  font-weight: 800;
  font-style: normal;
  src: url("../fonts/Graphik-Black.otf");
}

@font-face {
  font-family: "Graphik";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/Graphik-Super.otf");
}

@font-face {
  font-family: "Graphik";
  font-weight: 900;
  font-style: italic;
  src: url("../fonts/Graphik-SuperItalic.otf");
}
