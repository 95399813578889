@use "../utilities";

// Molecule Stylings
@use "../molecules/mol-casestudy-descriptor_list.scss";
@use "../molecules/mol-casestudy-hyperlinks.scss";

// Organism Stylings
@use "../organism/org-casestudy-info.scss";
@use "../organism/org-casestudy-section.scss";

.page-project {

    .section-container {
        display: flex;
        padding-top: 10rem;
        padding-bottom: 10rem;
    }

    // Page Globals
    .section-header {
        font-size: 1.5rem;
        font-weight: 300;
        line-height: 175%;
    }
}

@media screen and (max-width: 1650px) {
    .page-project {
        .section-container {
            padding-top: 7.5rem;
            padding-bottom: 7.5rem;
        }
    }
}

@media screen and (max-width: 720px) {
    .page-project {
        .section-container {
            padding-top: 4rem;
            padding-bottom: 4rem;
        }
    }
}