$basic_unit: 8px;
$col-width: 8.333%;

:root {
    --neutral-100: 255, 248, 245;
    --neutral-200: 255, 243, 240;
    --neutral-300: 255, 235, 230;
    --neutral-400: 255, 227, 220;
    --neutral-500: 223, 201, 192;
    --neutral-600: 191, 174, 164;
    --neutral-700: 159, 146, 136;
    --neutral-800: 128, 118, 109;
    --neutral-900: 58, 45, 50;

    --red-200: 237, 115, 132;
    --red-300: 230, 79, 121;
    --red-400: 221, 45, 119;
    --red-500: 211, 12, 123;
    --red-600: 188, 8, 95;
    --red-700: 164, 4, 70;
    --red-800: 138, 1, 48;

    --green-200: 157, 188, 190;
    --green-300: 121, 162, 165;
    --green-400: 85, 136, 139;
    --green-500: 50, 109, 113;
    --green-600: 15, 82, 87;
    --green-700: 12, 68, 77;
    --green-800: 9, 55, 67;

    --accent-200: 247, 235, 233;
    --accent-300: 242, 224, 221;
    --accent-400: 231, 202, 196;
    --accent-500: 219, 180, 173;
    --accent-600: 192, 160, 151;
    --accent-700: 166, 139, 129;
    --accent-800: 138, 118, 107;
}

@mixin transition_timing($duration: 0.45s) {
    transition: all ease-out $duration;
}

// Primary Button Standards

@mixin button_primary_resting {
    border: none;
    color: rgba(var(--neutral-100), 1.0);
    background-color: rgba(var(--neutral-900), 1.0);
    padding: 0.75rem 1.5rem;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 115%;
    vertical-align: middle;
    @include transition_timing();
}

@mixin button_primary_hover {
    background: linear-gradient(0deg, rgba(var(--neutral-100), 0.10) 0%, rgba(var(--neutral-100), 0.10) 100%), rgba(var(--neutral-900), 1.0);
    box-shadow: 0.25rem 0.5rem 0.25rem 0 rgba(var(--neutral-900), 0.10), 0.25rem 0.5rem 1rem 0 rgba(var(--neutral-900), 0.17);
}

@mixin button_primary_focus {
    outline: 2px solid rgba(var(--neutral-900), 1.0); 
}

@mixin button_primary_active {
    background: linear-gradient(0deg, rgba(var(--neutral-100), 0.10) 0%, rgba(var(--neutral-100), 0.10) 100%), rgba(var(--neutral-900), 1.0);
    box-shadow: 0.125rem 0.125rem 0.25rem 0 rgba(var(--neutral-900), 0.25) inset;
    outline: none;
}

// Secondary Button Standards

@mixin button_secondary_resting {
    color: rgba(var(--neutral-900), 1.0);
    font-weight: 500;
    @include transition_timing();
}

@mixin button_secondary_hover {
    background: linear-gradient(0deg, rgba(var(--neutral-900), 0.10) 0%, rgba(var(--neutral-900), 0.10) 100%), rgba(var(--accent-300), 1.0);
    box-shadow: 0.25rem 0.5rem 0.25rem 0 rgba(var(--neutral-900), 0.10), 0.25rem 0.5rem 1rem 0 rgba(var(--neutral-900), 0.17);
}

@mixin button_secondary_focus {
    outline: 2px solid rgba(var(--neutral-900), 1.0); 
}

@mixin button_secondary_active {
    background: linear-gradient(0deg, rgba(var(--neutral-900), 0.10) 0%, rgba(var(--neutral-900), 0.10) 100%), rgba(var(--accent-300), 1.0);
    box-shadow: 0.125rem 0.125rem 0.25rem 0 rgba(var(--neutral-900), 0.25) inset;
    outline: none;
}


@mixin primary_color($property, $alpha: 1.0) {
    #{$property}: rgba(000, 194, 209, $alpha);
}

@mixin secondary_color($property, $alpha: 1.0) {
    #{$property}: rgba(245, 241, 227, $alpha);
}

@mixin site_black_color($property, $alpha: 1.0) {
    #{$property}: rgba(var(--neutral-900), $alpha);
}