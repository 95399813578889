@use '../utilities';

.field {
    &-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    &-label, &-secondary_label {
        font-size: 0.875rem;
        line-height: 165%;
    }
    
    &-label {
        color: rgba(var(--neutral-900), 1.0);

        &-container {
            font-size: 0.875rem;
            line-height: 165%;
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            align-items: baseline;
        }

        &-required_tag { font-weight: 600; }
    }

    &-secondary_label {
        color: rgba(var(--neutral-700), 1.0);
    }

    &-input, &-textfield-container, &-textfield {
        align-self: stretch;
    }
    
    &-input, &-textfield-container {
        max-width: 100%;
        padding: 0.5rem 0.75rem;
        border: 1px solid rgba(var(--neutral-900), 1.0);
        border-radius: 0;
        align-self: stretch;
        background-color: rgba(var(--neutral-100), 1.0);
        font-style: normal;
    }

    &-input, &-textfield {
        font-size: 1rem;
        line-height: 120%;
        color: rgba(var(--neutral-900), 1.0);

        &::placeholder {
            color: rgba(var(--neutral-700), 1.0);
            font-style: italic;
        }
    }

    &-textfield-container:has(&-textfield:focus-visible) {
        border: 2px solid rgba(var(--neutral-900), 1.0);
        border-radius: 0;
        outline: none;
        .field-textfield {
            &:focus, &:focus-visible {
                border: none;
                outline: none;
            }
        }
        
    }

    &-input {
        min-height: 2.5rem;
        text-overflow: ellipsis;

        &:focus, &:focus-visible {
            border: 2px solid rgba(var(--neutral-900), 1.0);
            border-radius: 0;
            outline: none;
        }
    }

    &-textfield-container {
        display: flex;
        position: relative;
        flex-direction: column;
    }

    &-textfield {
        width: 100%;
        padding: 0;
        flex-grow: 1;
        background: none;
        border: none;
        min-height: 12.5rem;
        max-height: 24rem;
        resize: vertical;
        overflow-y: none;

        &-max_char_count {
            color: #9F9288;
            background: none;
            position: absolute;
            bottom: 0.25rem;
            right: 2rem;
            font-size: 0.875rem;
        }
    }

    &-submit {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        @include utilities.button_primary_resting();
        &:hover { @include utilities.button_primary_hover(); }
        &:focus { @include utilities.button_primary_focus(); }
        &:active { @include utilities.button_primary_active(); }
        &:visited { @include utilities.button_primary_resting(); }
    }

    &-error {
        .field-input, .field-textfield-container {
            border-color: rgba(var(--red-700), 1.0);
        }

        .field-error-message {
            color: rgba(var(--red-700), 1.0);
        }
    }

    &-disabled {
        .field-input, .field-textfield-container {
            background-color: rgba(var(--neutral-600), 1.0);
        }

        .field-input, .field-textfield, .field-textfield-max_char_count {
            color: rgba(var(--neutral-300), 1.0);
            
            &::placeholder {
                color: rgba(var(--neutral-300), 1.0);
                font-style: italic;
            }
        }
    }
}

@media (pointer: coarse) {
    .field {
        &-textfield {
            &-max_char_count {
                right: 0.5rem
            }
        }
    }
}