@use '../utilities';

.case_study-section {
    scroll-margin-top: 81px;
    
    &-container {
        padding: 3.75rem 4rem;
        display: flex;
        gap: 2rem;
    }

    &-name {
        font-size: 1.5rem;
        font-weight: 300;
        line-height: 165%;
        color: rgba(var(--neutral-900), 1.0);
        text-transform: uppercase;
    }

    &_hightlight, &_testimonials {
        .case_study-section-section_container {
            display: flex;
            flex-direction: column;
            flex-shrink: 1;
            flex-basis: 83.333%;
        }
    }

    &_plain, &_highlight, &_testimonials {
        background-color: rgba(var(--neutral-100), 1.0);
        margin: {
            left: -4rem;
            right: -4rem;
        }
        padding: {
            left: 4rem;
            right: 4rem;
        }
        border: 0 none;

        .case_study-section-section_container {
            display: flex;
            flex-direction: row;
            flex-shrink: 1;
            flex-basis: 83.3333%;
            gap: 2rem;
            align-items: flex-start;
        }

        .case_study-section-content_container {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            flex-shrink: 1;
            flex-grow: 1;
            flex-basis: 58.333%;
        }

        .case_study-section-name {
            top: calc(81px + 4rem);
            display: block;
            position: sticky;
            flex-shrink: 1;
            flex-basis: 25%;
        }
    }

    &_plain, &_highlight {
        .case_study-section-text_content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            gap: 2rem;
            // Basic Text Styling
            &, & p {
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 185%;
                color: rgba(var(--neutral-900), 1.0);
            }

            h3 {
                font-weight: 300;
                font-size: 2rem;
                color: rgba(var(--accent-800), 1.0);
            }

            blockquote {
                display: block;
                width: 100%;
                padding-left: 1.5rem;
                color: rgba(var(--accent-800), 1.0);
                border-left: 0.5rem solid rgba(var(--accent-800), 1.0);
                font: {
                    size: 1.5rem;
                    weight: 400;
                }
                line-height: 165%;
            }

            span { font-weight: 600; }
            a, a:visited, span a, span a:visited { color: rgba(var(--accent-800), 1.0);  }
        }

        .case_study-section-grid {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            justify-items: center;
        }

        .case_study-section-grid_item {
            object-fit: cover;
            height: auto;
            max-width: 100%;
            border-radius: 0.25rem;
        }
    }

    &_gallery, &_grid, &_testimonials {
        margin: {
            left: -4rem;
            right: -4rem;
        }
        padding: {
            left: 4rem;
            right: 4rem;
        }
    }

    &_grid {
        .grid_container {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(12rem, 2fr));
            gap: 2rem;
            justify-items: center;
        }
        .case_study-section-grid_item {
            object-fit: cover;
            height: auto;
            max-width: 100%;
            @include utilities.site_black_color(border-color);
            border: 0 none;
            border-radius: 0.25rem;
        }
    }

    &_testimonials {
        .case_study-section {
            &-section_container { gap: 1rem; }
            &-text_content {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                color: rgba(var(--neutral-900), 1.0);
                blockquote {
                    width: 100%;
                    display: block;
                    line-height: 185%;
                    font: {
                        size: 1.5rem;
                        weight: 400;
                        style: normal;
                    }
                }
                em {
                    letter-spacing: 0.5px;
                    padding-top: 0.5rem;
                    font: {
                        size: 1rem;
                        weight: 400;
                        style: italic;
                    }
                    color: rgba(var(--accent-800), 1.0);
                    display: block;
                }
            }
        }
    }
}

@media screen and (max-width: 1650px) {
    .case_study-section {
        .section-empty_col { display: none; }
        &_highlight, &_testimonials {
            .case_study-section-section_container { flex-grow: 1; }
            .case_study-section-text_content { flex-shrink: 0; }
        }
    }
}

@media screen and (max-width: 1450px) {
    .case_study-section {
        &_plain { flex-direction: column; }
        &_highlight, &_testimonials {
            .case_study-section-text_content { flex-shrink: 1; }
        }
        &_highlight {
            .case_study-section-content_container { flex-direction: column; }
        }
    }
}

@media screen and (max-width: 900px) {
    .case_study-section {
        &_plain, &_highlight, &_testimonials {
            .case_study-section-section_container { flex-direction: column; }
            .case_study-section-name {
                position: relative;
                top: 0;
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .case_study-section {
        &_grid { grid-template-columns: repeat(auto-fit, minmax(10rem, 100%)); }

        &_plain, &_highlight, &_gallery, &_grid, &_testimonials {
            margin: {
                left: -2rem;
                right: -2rem;
            }
            padding: {
                left: 2rem;
                right: 2rem;
            }
        }

        &_plain, &_highlight, &_testimonials {
            .case_study-section {
                &-text_content {
                    p, blockquote {
                        font-size: 1.125rem;

                    }
                }
            }
        }

        &_plain, &_highlight {
            .case_study-section {
                &-text_content {
                    blockquote {
                        margin: 0;
                    }
                }
            }
        }
    }
}