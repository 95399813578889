.gallery_container {
    display: flex;
    flex-shrink: 1;
    flex-basis: 83.333%;
    flex-direction: column;
    gap: 2rem;
}

@media screen and (max-width: 1650px) {
    .gallery_container {
        flex-shrink: 0;
        flex-grow: 1;
    }
}

.embla {
    flex-basis: 50%;
    flex-shrink: 1;
    --slide-height: 19rem;
    --slide-spacing: 1rem;
    --slide-size: 100%;
}

.embla__viewport {
    overflow: hidden;
}

.embla__container {
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
    max-height: 70vh;
}

.embla__slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
}

.embla__slide__image {
    display: flex;
    justify-content: center;
    img {
        max-height: 37.5rem;
        max-width: 100%;
        width: auto;
        height: auto;
        object-fit: cover;
    }
}

.gallery-thumbs_container {
    flex-basis: 33.33%;
    flex-grow: 1;
}

.embla-thumbs__viewport {
    display: grid;
    gap: 2rem;
}

@media screen and (min-width: 1300px) {
    .embla-thumbs__viewport { grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));  }
}

@media screen and (min-width: 1600px) {
    .embla-thumbs__viewport { grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));  }
}

@media screen and (min-width: 2300px) {
    .embla-thumbs__viewport { grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));  }
}

@media screen and (max-width: 1299px) {
    .embla {
        flex-grow: 1;
        flex-shrink: 0;
    }
    .gallery-thumbs_container { display: none; }
    .embla-thumbs__viewport { display: none;  }
}


.embla-thumbs__slide__image {
    & {
        background: none;
        padding: 1rem;
        border: 1px solid rgba(0,0,0,0.0);
    }

    &:hover {
        background-color: rgba(0, 194, 209, 0.1);
    }

    .gallery-thumbnail {
        object-fit: cover;
        height: auto;
        max-width: 100%;
    }
}

.embla-thumbs__slide--selected {
    .embla-thumbs__slide__image {
        background-color: rgba(0, 194, 209, 0.1);
        border: 1px solid #00C2D1;
    }
}

.gallery-controls_container {
    & {
        justify-content: center;
        display: flex;
        gap: 0.5rem;
    }
    .gallery-control {
        & {
            background-color: rgba(0, 194, 209, 0.1);
            padding: 0.75rem;
            border: 0 none #fff;
        }

        &:hover, &:active { .lucide { color: #fff; }}

        &:hover { background-color: #00C2D1; }

        &:active { background-color: #0395A0; }

        .lucide {
            color: #00C2D1;
            stroke-width: 0.25rem;
        }
    }
}