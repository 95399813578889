@use '../utilities';

.page-home {
  .section {
    &-container {
      height: calc(100dvh - 80px);
      display: flex;
      gap: 2rem;
      position: relative;
      padding-top: 4rem;
      padding-bottom: 4rem;
      overflow: hidden;
    }
    &-empty_col { display: block; }
    &-index {
      position: absolute;
      bottom: 4rem;
      right: 4rem;
    }
  }
}

.home-intro {
  & {
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    color: rgba(var(--neutral-900), 1.0);
  }

  .intro-primary_text {
    .intro-header_name, .intro-tagline_title {
      text-transform: uppercase;
      leading-trim: both;
      text-edge: cap;
    }

    .intro-header_name {
      color: rgba(var(--neutral-900), 1.0);
      font: {
        size: 10rem;
        style: italic;
        weight: 900;
      }
      line-height: 93.5%;
      text-align: right;
    }

    .intro-tagline_title {
      color: rgba(var(--accent-700), 1.0);
      font: {
        size: 5rem;
        style: normal;
        weight: 300;
      }
    }
  }

  .intro-secondary_text {
    position: absolute;
    bottom: 4rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  .intro-scroll_down {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }
}

.home-project {
  .project-image {
    &-container {
      flex-basis: utilities.$col-width * 3;
      position: relative;
      overflow: hidden;
      border-radius: 0.25rem;
    }

    &-loading {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    
    &-wrapper { max-width: 100%; }

    & {
      max-height: 37.5rem;
      max-width: 100%;
      width: auto;
      height: auto;
      object-fit: cover;
    }
  }
  
  .project-info {
    &-container {
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      padding: 1.5rem;
      gap: 0.5rem;
      position: relative;
      overflow: hidden;
    }

    &-loading {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }

    &-title {
      font: {
        size: 2rem;
        style: normal;
        weight: 500;
      }
      line-height: 125%;
      text-transform: uppercase;
    }

    &-timerange {
      font: {
        size: 1.125rem;
        style: normal;
        weight: 400;
      }
    }
  }

  .project-link {
    &-container {
      width: 8.333%;
      display: flex;
    }
    width: 100%;
    display: block;
    text-align: center;
    & { @include utilities.button_primary_resting; }
    &:hover { @include utilities.button_primary_hover; }
    &:focus { @include utilities.button_primary_focus; }
    &:active { @include utilities.button_primary_active; }
    &:visited { @include utilities.button_primary_resting; }
    padding: 0.75rem !important;
  }

  &.home-askuity-fusion, &.home-tmx-matrix, &.home-goat-risk {
    .project-info {
      &-container {
        color: rgba(var(--neutral-100), 1.0);
        background-color: rgba(var(--neutral-100), 0.25);
      }

      &-loading { background-color: rgba(var(--neutral-100), 1.0) }
    }

    .project-image {
      &-loading { background-color: rgba(var(--neutral-100), 1.0) }
    }

    .project-link { 
      background-color: rgba(var(--neutral-100), 1.0);
      color: rgba(var(--neutral-900), 1.0);
      &:hover, &:active { background: linear-gradient(0deg, rgba(var(--neutral-900), 0.10) 0%, rgba(var(--neutral-900), 0.10) 100%), rgba(var(--neutral-100), 1.0); }
    }

    .section-index { color: rgba(var(--neutral-100), 0.75); }
  }

  &.home-tmx-money, &.home-fx-tech {
    .project-info {
      &-container {
        color: rgba(var(--neutral-900), 1.0);
        background-color: rgba(var(--neutral-900), 0.25);
      }

      &-loading { background-color: rgba(var(--neutral-900), 1.0) }
    }

    .project-image {
      &-loading { background-color: rgba(var(--neutral-900), 1.0) }
    }

    .section-index { color: rgba(var(--neutral-900), 0.75); }
  }

  &.home-askuity-fusion { background-color: #F96302; }
  &.home-tmx-money { background-color: #73C6CB; }
  &.home-tmx-matrix { background-color: #161A1E; }
  &.home-fx-tech { background-color: #F4D15C; }
  &.home-goat-risk { background-color: #EE3A4C; }
}

@media screen and (max-width: 1650px) {
  .page-home {
    .section {
      &-container { gap: 4rem }
      &-empty_col { display: none; }
    }

    .home-project {
      .project-link { 
        &-container { min-width: 10rem; }
      }
    }
  }
}

@media screen and (max-width: 1650px) and (min-width: 1201px) {
  .page-home {
    .home-project {
      .project-info {
        &-container { flex-grow: 1; }
      }
    }
  }
}

@media screen and (min-width: 1201px) {
  .page-home {
    .home-intro {
      .intro-primary_text { min-width: utilities.$col-width * 6; }
    }

    .home-project {
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .project-info {
        &-container {
          margin-left: -1.5rem;
          margin-right: -1.5rem;
          flex-basis: 33.333%;
        }
      }

      .project-link {
        &-container {
          margin-left: -1rem;
          margin-right: -1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .page-home {
    .home-intro {
      .intro-header_name {
        font-size: 8rem;
        text-align: center;
      }
      .intro-tagline_title { font-size: 5rem; }
    }

    .home-project {
      flex: 1 0 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;

      .section-empty_col:nth-of-type(5) {
        display: flex;
        flex-grow: 1;
        align-self: stretch;
      }
    }

    .project-image {
      &-container {
        display: flex;
        align-self: stretch;
        align-items: center;
        max-height: 24rem;
        aspect-ratio: 4/3;
      }
    }

    .project-info {
      &-container {
        align-items: stretch;
        align-self: stretch;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .page-home {
    .home-intro {
      .intro-header_name { font-size: 4rem; }
      .intro-tagline_title { font-size: 2.5rem; }

      .intro-secondary_text {
        top: 4rem;
        flex-direction: column-reverse;
      }
    }
  }
}

@media screen and (min-width: 721px) {
  .page-home {
    .home-project {
      margin: 0 -4rem;
      padding: 4rem;
    }
  }
}

@media screen and (max-width: 720px) {
  .page-home {
    .home-project {
      margin: 0 -2rem;
      padding: 2rem;
    }

    .section {
      &-index {
        bottom: 2rem;
        right: 2rem;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .page-home {
    .home-intro {
      .intro-header_name { font-size: 3rem; }
      .intro-tagline_title { font-size: 2rem; }
    }
  }
}