@use '../utilities';

.section-footer {
    &.section {
        &-container {
            height: calc(100dvh - 80px);
            display: flex;
            gap: 2rem;
            position: relative;
            padding-top: 4rem;
            padding-bottom: 4rem;
            overflow: hidden;
        }
    }

    & {
        flex-direction: row;
        align-content: center;
        justify-content: center;
        align-items: center;
        color: rgba(var(--neutral-900), 1.0);
    }

    .footer-header_name, .footer-tagline_title {
        text-transform: uppercase;
        leading-trim: both;
        text-edge: cap;
    }

    .footer-header_name {
        color: rgba(var(--neutral-900), 1.0);
        font: {
          size: 10rem;
          style: italic;
          weight: 900;
        }
        line-height: 93.5%;
        text-align: right;
    }

    .footer-tagline_title {
        color: rgba(var(--accent-700), 1.0);
        font: {
          size: 5rem;
          style: normal;
          weight: 300;
        }
    }

    .footer-secondary_text {
        position: absolute;
        bottom: 4rem;
        left: 4rem;
        right: 4rem;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
    }

    .footer-scroll_up {
        &-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;
            background: none;
            border: none;
            margin-left: -0.5rem;
            margin-right: -0.5rem;
            padding: 0.5rem 0.5rem;
            @include utilities.button_secondary_resting();
            &:hover { @include utilities.button_secondary_hover(); }
            &:focus { @include utilities.button_secondary_focus(); }
            &:active { @include utilities.button_secondary_active(); }
            &:visited { @include utilities.button_secondary_resting(); }
        }
        font-size: 1rem;
        font-weight: 400;
    }

    .footer-social_links {
        &-container {
            display: flex;
            gap: 1rem;
        }
    }

    .footer-hyperlink {
        margin: -0.5rem;
        padding: 0.5rem;
        @include utilities.button_secondary_resting();
        &:hover { @include utilities.button_secondary_hover(); }
        &:focus { @include utilities.button_secondary_focus(); }
        &:active { @include utilities.button_secondary_active(); }
        &:visited { @include utilities.button_secondary_resting(); }
        svg {
            display: block;
            stroke-width: 1.25px;
        }
    }

}

@media screen and (min-width: 1201px) {
    .section-footer {
        .footer-primary_text { min-width: utilities.$col-width * 6; }
    }
}

@media screen and (max-width: 1200px) {
    .section-footer {
        .footer-header_name {
          font-size: 8rem;
          text-align: center;
        }

        .footer-tagline_title { font-size: 5rem; }
    }
}

@media screen and (max-width: 900px) {
    .section-footer {
        .footer-secondary_text {
            left: auto;
            right: auto;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .footer-copyright { order: 3 }
        }

        .footer-header_name { font-size: 4rem; }
        .footer-tagline_title { font-size: 2.5rem; }
    }
}

@media screen and (max-width: 380px) {
    .section-footer {
        .footer-header_name { font-size: 3rem; }
        .footer-tagline_title { font-size: 2rem; }
    }
}