@use '../utilities';

.section-site_header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.header-container {
    min-height: 2rem;
    z-index: 10;
    position: relative;
}

.header-container, .header-mobile_expasion {
    display: flex;
    padding: {
        top: 1.5rem;
        bottom: 1.5rem;
    }
    align-items: center;
    gap: 0.5rem;
    border-bottom: 1px solid rgba(var(--neutral-900), 1.0);
    background-color: rgba(var(--accent-300), 1.0);

    .site-pages {
        align-items: center;
        gap: 1rem;
    }

    .site_header-link {
        margin: -0.5rem;
        padding: 0.5rem;
        @include utilities.button_secondary_resting();
    }
    .site_header-link:hover { @include utilities.button_secondary_hover(); }
    .site_header-link:focus { @include utilities.button_secondary_focus(); }
    .site_header-link:active { @include utilities.button_secondary_active(); }
    .site_header-link:visited { @include utilities.button_secondary_resting(); }
    

    .location-text, .site-name { flex-grow: 1; }
}

.header-expansion_button, .site-pages { min-height: 2rem; }

.header-expansion_button {
    padding: 7px;
    border: 0 none;
    background: none;
    svg { display: block; }
    color: rgba(var(--neutral-900), 1.0);

    &:active, &:focus {
        @include utilities.primary_color(background-color, 0.1);
        @include utilities.primary_color(color);
        outline: none;
    }
}

.header-mobile_expasion {
    position: relative;
    height: calc(100vh - 80px - 49px);
    @include utilities.secondary_color(background-color);
    flex-direction: column;
    z-index: 4;

    .site-pages {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        gap: 3rem;
        font: {
            size: 1.5rem;
        }
    }
}

@media screen and (min-width: 721px) {
    .header {
        &-container, &-mobile_expansion {
            padding: { left: 4rem; right: 4rem; }
        }

        &-expansion_button_container { display: none; }

        &-container {
            .site-pages { display: inline-flex; }
        }
    }
}

@media screen and (max-width: 720px) {
    .header-container, .header-mobile_expasion  {
        padding: {
            left: 2rem;
            right: 2rem;
        }
    }

    .header-expansion_button_container { display: block; }
    .header-container .site-pages { display: none; }
}