.page-project .project-info {
    .intro-descriptor {
        &-container{
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            column-gap: 2rem;
            row-gap: 2rem;
        }
    }
    
    .descriptor {
        &-container {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            flex-basis: 33%;
            flex-grow: 1;
            align-self: flex-start;
        }
    
        &-title {
            font-size: 1.5rem;
            font-weight: 300;
            line-height: 165%;
            text-transform: uppercase;
            color: rgba(var(--accent-800), 1.0);
        }
    
        &-list {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
    
        }
    
        &-list-item {
            font-size: 1.125rem;
            font-weight: 400;
            line-height: 165%;
            color: rgba(var(--neutral-900), 1.0);
        }
    }
}

@media screen and (max-width: 720px) {
    .page-project .project-info {
        .descriptor {
            &-container { flex-basis: 100%; }
        }
    }
}