@use "../utilities";

.page-project {
// Intro Section

    .project-intro {
        min-height: 80vh;
        display: flex;
        gap: 2rem;
    }

    // Information Section

    .project-info {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        flex-basis: utilities.$col-width * 4;
        flex-shrink: 1;
        
        .intro-title {
            &-container {
                display: flex;
                gap: 1rem;
                flex-direction: column;
            }

            &-title {
                font-size: 2.5rem;
                font-weight: 600;
                line-height: 100%;
                color: rgba(var(--neutral-900), 1.0);
            }

            &-client {
                font-size: 2rem;
                font-weight: 300;
                line-height: 100%;
                color: rgba(var(--accent-800), 1.0);
            }
        }

        .intro-project_goal {
            &-container {
                padding: 1rem;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                align-items: flex-start;
                align-self: stretch;
                background-color: rgba(var(--accent-800), 0.10);;
            }

            &-header { color: rgba(var(--accent-800), 1.0); }

            &-description {
                font-size: 1.125rem;
                font-weight: 500;
                line-height: 185%;
                color: rgba(var(--neutral-900), 1.0);
            }
        }
    }

    // Main Project Image Section

    .project-image {
        .intro-image {
            &-container {
                top: calc(81px + 10rem);
                position: sticky;
                overflow: hidden;
                min-height: 60vh;
            }
            & {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 0.5rem;
            }

            source { display: none; }

            img {
                width: 100%;
                border: 0 none;
                border-radius: 0.5rem;
            }

            caption {
                font-size: 1rem;
                font-weight: 400;
                line-height: 120%;
                padding: 0.25rem 0.5rem;
                color: rgba(var(--neutral-900), 1.0);
                background-color: rgba(var(--accent-800), 0.10);
            }
        }

        &-loading {
            position: absolute;
            border-radius: 0.25rem;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: rgba(var(--neutral-900), 1.0);
            z-index: 2;
          }
    }
}

@media screen and (max-width: 1650px) {
    .page-project {
        .project-intro {
            .section-empty_col:first-of-type { display: none; }
            .section-empty_col:last-of-type { display: none; }
            .project-info { flex-grow:1; }
        }
    }
}

@media screen and (min-width: 1201px) {
    .page-project {
        .project-image { width: utilities.$col-width * 5; }
    }
}

@media screen and (max-width: 1200px) {
    .page-project {
        .project-intro {
            flex-direction: column-reverse;

            .section-empty_col { display: none; }

            .intro-title {
                &-container { padding: 0 0; }
            }

            .project-image {
                flex-basis: utilities.$col-width * 5;
                flex-grow: 1;
                align-self: stretch;
                .intro-image {
                    &-container { min-height: 17.5rem; }
                }
                //min-height: 200px;
            }
        }

        .intro-image {
            &-container {
                .image img {
                    max-height: 20rem;
                    object-fit: cover;
                }
            }
        }
    }
}