@use "utilities";

*,
*::before,
*::after {
  margin: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-family: "Graphik", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root, body { 
  font-size: utilities.$basic_unit * 2;
  background-color: rgba(var(--neutral-200), 1.0);
}

a {
  text-decoration: none;
  &:visited { color: inherit; }
}

ul {
  list-style: none;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}

.page {
  &-content { margin: 81px 4rem; }

  .section-empty_col { width: utilities.$col-width; }
}

@media screen and (max-width: 720px) {
  .page {
    &-content { margin: 81px 2rem; }
  }
}

.page-home, .page-portfolio, .page-about, .page-contact {
  .projects {
    &-title {
      & {
        font: {
          size: 2.5rem;
          style: italic;
          weight: 900;
        }
        line-height: 130%;
        text-transform: uppercase;
      }
      
      &_container {
        margin : {
          top: 1rem;
          left: 1rem;
          right: 1rem;
          bottom: 0.5rem;
        }
      }
    }
  }
}

.page-home, .page-portfolio {
  .projects {
    &-section {
      & {
        display: flex;
        flex-direction: column;
      }

      &_container {
        & > .container-blank { flex-grow: 1; }
        
        display: flex;
      }
    }
  }

  .item {
    &-container {
      & {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        padding: 1rem 0;
      }

      &:hover {
        @include utilities.primary_color(background-color, 0.1);
        @include utilities.primary_color(color);
      }
    }

    &-image_container {
      width: 20rem;
      height: 20rem;
      @include utilities.site_black-color(background-color);
      border-radius: 0.25rem;
      overflow: hidden;
      display: flex;
      justify-content: center;
    }

    &-image { height: 100%; }

    &-text_container {
      margin: 0 2rem;
      display: inline-flex;
      flex-direction: column;
      gap: 0.5rem;
    }
    
    &-title {
      & {
        font: {
          size: 2rem;
          weight: 800;
          style: normal;
        }
        line-height: 120%;
        text-transform: lowercase;
      }
    }
    &-description {
      font: {
        size: 1rem;
        weight: 400;
        style: normal;
      }
      line-height: 155%;
    }
  }
}