@use '../utilities';

.page-contact {
    .page-content {
        padding: { top: 80px; bottom: 80px; }
        min-height: 80vh;
        display: flex;
        gap: 2rem;
        color: rgba(var(--neutral-900), 1.0);
    }

    .contact_content {
        &-container {
            display: flex;
            flex-direction: column;
            flex-basis: 41.667%;
            flex-shrink: 1;
            gap: 2.5rem;
        }
    }

    .contact_introduction {
        &-container {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        &-header {
            font: {
                size: 2rem;
                weight: 300;
            }
            line-height: 125%;
            text-transform: uppercase;
        }

        &-social_links {
            &-container {
                display: flex;
                flex-direction: row;
                gap: 1.5rem;
            }
        }

        &-hyperlink {
            margin: -0.5rem;
            padding: 0.5rem;
            @include utilities.button_secondary_resting();
            &:hover { @include utilities.button_secondary_hover(); }
            &:focus { @include utilities.button_secondary_focus(); }
            &:active { @include utilities.button_secondary_active(); }
            &:visited { @include utilities.button_secondary_resting(); }
            svg {
                display: block;
                stroke-width: 1.25px;
            }
        }
    }

    .contact_introduction-intro_subtext, .contact_form-header {
        font: {
            size: 1.125rem;
            weight: 400;
        }
        line-height: 165%;
    }

    .contact_form {
        &-container {
            display: flex;
            flex-direction: column;
            gap: 2.5rem;
        }
        
        &-form {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
        }

        &-names {
            display: flex;
            flex-direction: row;
            gap: 2rem;
            .field-container { flex: 1 0 0; }
        }
    }

    .contact_image {
        &-container {
            flex-basis: 33.33%;
            flex-shrink: 1;
        }
    }
}

@media screen and (max-width: 1650px) {
    .page-contact {
        .section-empty_col { display: none; }
        .contact_content {
            &-container { flex: 1 0 0; }
        }
    }
}

@media screen and (max-width: 1200px) {
    .page-contact {
        .page-content {
            flex-direction: column-reverse;
            gap: 5rem;
        }

        .contact_content {
            &-container { gap: 5rem; }
        }
    }
}

@media screen and ((max-width: 720px)) {
    .page-contact {
        .contact_form {
            &-names {
                flex-direction: column;
            }
        }
    }
}