.project-info {
    .intro-hyperlinks {
        &-container {
            display: flex;
            align-self: stretch;
            flex-wrap: wrap;
            align-items: flex-start;
            row-gap: 1rem;
            column-gap: 2rem;
        }

        &-link {
            display: flex;
            flex: 1 0 0;
            min-width: 15rem;
            align-items: center;
            gap: 0.25rem;
            font-size: 1.125rem;
            font-weight: 700;
            line-height: 165%;
            color: rgba(var(--neutral-900), 1.0);

            &:hover {
                color: rgba(var(--neutral-900), 1.0);
                background-color: rgba(var(--neutral-900), 0.1);
            }
        
            &:visited { color: rgba(var(--neutral-900), 1.0); }
        }
    }
}

@media screen and (max-width: 1200px) {
    .project-info {
        .intro-hyperlinks-link {
            min-width: 17rem;
        }
    }
}

@media screen and (max-width: 720px) {
    .intro-hyperlinks {
        &-container {
            flex: {
                basis: 80%;
                shrink: 0;
            }
        }
    }
}